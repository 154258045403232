import React, { Component, Fragment } from 'react';
import { hot } from 'react-hot-loader';
import styled, { createGlobalStyle } from 'styled-components';
import io from 'socket.io-client';
import update from 'immutability-helper';

const CANVAS_WIDTH = 800;

const CANVAS_HEIGH = 800;

const PLAYER_COLORS = [
  '#FA3831',
  '#FEFF0E',
  '#24FF23',
  '#7EFED8',
  '#4137FF',
];

const PLANET_COLOR = '#4B4B4B';

const HOST = process.env.NODE_ENV === 'development' ? 'localhost:3001' : 'pluto.newtonwars.com';

const GlobalStyle = createGlobalStyle`
  body, html, #app {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  #app {
    background: black url(galaxy.jpg) no-repeat center center;
    background-size: cover;
  }
`;

const Screen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const formatTime = (seconds) => {
  const m = Math.floor(seconds / 60);
  const s = seconds - (m * 60);

  return `${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
};

class App extends Component {
  constructor(props) {
    super(props);

    this.canvas = React.createRef();
    this.lastData = {};
    this.roomInfo = {};
    this.ctx = null;
  }

  componentDidMount() {
    this.ctx = this.canvas.current.getContext('2d');

    const socket = io(`//${HOST}`);

    socket.on('STATE_UPDATE', ({ data }) => {
      if (!data) return;
      this.lastData = update(this.lastData, data);
      this.drawFrame();
    });

    socket.on('SERVER_INFO', ({ rooms }) => {
      this.roomInfo = rooms[0];
      socket.emit('JOIN_ROOM', { roomId: rooms[0].id });
    });

    socket.on('INITIAL_DATA', (data) => {
      this.lastData = data;
      this.drawFrame();
    });
  }

  drawFrame() {
    const {
      state,
      timer,
      endTimer,
      players,
      planets,
      bullets,
      trails,
      items,
    } = this.lastData;

    const playerIdColorMap = players.reduce((obj, player, index) => {
      obj[player.id] = PLAYER_COLORS[index];
      return obj;
    }, {});

    this.ctx.fillStyle = 'rgba(0, 0, 0, 0)';
    this.ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGH);

    this.ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
    this.ctx.font = '15px Trebuchet MS';
    this.ctx.fillText(`… to play, telnet pluto.newtonwars.com ${this.roomInfo.telnetPort}`, 10, CANVAS_HEIGH - 15);

    if (state !== 'WAITING_FOR_PLAYERS') {
      const time = formatTime(state === 'PLAYING' ? timer : endTimer);
      this.ctx.fillText(`${time}`, CANVAS_WIDTH - 50, 25);
    } else {
      this.ctx.fillText('Waiting for players …', 10, 25);
    }

    players.forEach(({ position: [x, y], id, item }) => {
      this.ctx.save();

      // Dot
      this.ctx.fillStyle = playerIdColorMap[id];
      this.ctx.beginPath();
      this.ctx.arc(x, y, 3, 0, 2 * Math.PI);
      this.ctx.fill();

      if (item === 'SHIELD') {
        this.ctx.strokeStyle = playerIdColorMap[id];
        this.ctx.beginPath();
        this.ctx.arc(x, y, 6, 0, 2 * Math.PI);
        this.ctx.stroke();
      }

      this.ctx.restore();
    });

    bullets.concat(trails).forEach(({ path, playerId, count }) => {
      this.ctx.save();
      this.ctx.beginPath();
      this.ctx.moveTo(path[0][0], path[0][1]);

      this.ctx.globalAlpha = 0.75;

      for (let i = 1; i < path.length - 2; i += 1) {
        const dx = (path[i][0] + path[i + 1][0]) / 2;
        const dy = (path[i][1] + path[i + 1][1]) / 2;
        this.ctx.quadraticCurveTo(path[i][0], path[i][1], dx, dy);
      }

      this.ctx.strokeStyle = playerIdColorMap[playerId];
      this.ctx.lineWidth = 1.5;
      this.ctx.stroke();
      this.ctx.restore();
    });

    planets.forEach(({ radius, position: [x, y] }) => {
      // this.ctx.fillStyle = PLANET_COLOR;
      // this.ctx.beginPath();
      // this.ctx.arc(x, y, radius, 0, 2 * Math.PI);
      // this.ctx.fill();
      this.ctx.font = `bold ${radius * 2}px Apple Color Emoji`;
      this.ctx.fillText(radius < 30 ? '🌖' : '🌏', x - (1 * radius), y + (0.85 * radius));
    });

    items.forEach(({ position: [x, y] }) => {
      this.ctx.font = `bold ${10 * 2}px Apple Color Emoji`;
      this.ctx.fillText('🌕', x - (1 * 10), y + (0.85 * 10));
    });

    players.forEach(({ name, kills, deaths, id, admin, energy, item }, index) => {
      this.ctx.save();

      // HUD
      this.ctx.fillStyle = playerIdColorMap[id];
      this.ctx.font = 'bold 14px Trebuchet MS';
      const nameAndStats = `${name} (${kills}:${deaths})`;
      this.ctx.fillText(admin ? `${nameAndStats}*` : nameAndStats, 15 + (index * 150), 25);

      this.ctx.font = 'bold 12px Trebuchet MS';
      this.ctx.fillText(`Energy: ${energy}`, 15 + (index * 150), 45);

      if (item) {
        this.ctx.fillText(`Item: ${item}`, 15 + (index * 150), 65);
      }

      this.ctx.restore();
    });
  }

  render() {
    return (
      <Fragment>
        <GlobalStyle />
        <Screen>
          <canvas width={CANVAS_WIDTH} height={CANVAS_HEIGH} ref={this.canvas} />
        </Screen>
      </Fragment>
    );
  }
}

export default hot(module)(App);
